@import '../../styles/propertySets.css';

.wrapperImages {
    /* aspect-ratio: 8024 / 2649; */
    /* z-index: 0;

    > picture {
        display: block;
        min-width: 100vw;
        object-fit: cover;
        width: 100vw;
        height: calc(100vw / (50 / 33));

        @media (--viewportSmall) {
            height: calc(100vw / (8024 / 2649));
        }
    } */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 62%;
    margin-bottom: 23px;

}

.wrapperImages picture,
.wrapperImages img {
  display: flex;
  /* flex-basis: 62%; */
  flex-grow: 1;
  max-width: 100%;
  object-fit: cover;
  max-height: 500px;
}

.responsiveHeroTitleContainer {
    /* display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center; */
    /* vertical */
    /* justify-content: center; */
    /* horizontal */
    /* min-width: 100vw; */
    /* transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1); */
}
.responsiveHeroTitleContainer span[id*=animatedText] {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
}

.heroCustomerSecondaryButton {
    float: left;
    background-color: --marketplaceColor;
    padding: 18px 0 0 0;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.4);
    animation-delay: 0.8s;
    margin: 16px 0;
    width: 272px;

    @media (--viewportMedium) {
        display: block;
        width: 272px;
        margin: auto 6px;
    }

    &:hover,
    &:focus {
        background-color: var(--marketplaceColorDark);
    }
}